'use client';

import { useUser } from '@auth0/nextjs-auth0/client';

import H3 from '@/components/common/H3';
import { PublicationsGrid } from '@/features/list-publication';
import { GetPublicationsByCountReturnType, GetPublicationsReturnItemType } from '@/shared/types';

export default function LastPublications({
  lastPubs
}: {
  lastPubs: GetPublicationsByCountReturnType;
}) {
  const { user } = useUser();

  return (
    <article className="space-y-4 lg:space-y-8">
      <H3 size="x-large">Последние объявления</H3>
      <PublicationsGrid
        publications={lastPubs as GetPublicationsReturnItemType[]}
        currentUserEmail={user?.email}
      />
    </article>
  );
}
