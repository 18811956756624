import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/components/main-page/last-publications/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dogs-blog/dogs-blog/src/components/main-page/what-suggesting/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/buttons/BackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/buttons/ShareButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/call-to-number/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/HiddenLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dogs-blog/dogs-blog/src/shared/ui/theme/index.tsx");
