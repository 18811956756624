import clsx from 'clsx';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  color?: 'black' | 'white';
  weight?: 'normal' | 'bold';
  [key: string]: any;
};

export default function H3({
  children,
  className,
  size = 'medium',
  color = 'black',
  weight = 'bold',
  ...rest
}: Props) {
  const sizeClasses = {
    small: 'text-lg',
    medium: 'text-lg lg:text-2xl',
    large: 'text-2xl lg:text-4xl',
    'x-large': 'text-2xl lg:text-5xl'
  };

  const colorClasses = {
    black: 'text-letters-black dark:text-white',
    white: 'text-white dark:text-letters-black'
  };

  const weightClasses = {
    normal: 'font-normal',
    bold: 'font-bold'
  };

  return (
    <h3
      {...rest}
      className={clsx(
        sizeClasses[size],
        weightClasses[weight],
        'leading-snug',
        colorClasses[color],
        className
      )}
    >
      {children}
    </h3>
  );
}
